import React from 'react';

export function FlexLine(props) {

  return (
    <div
      className='flex-line'
    >
    </div>
  )
}
